import "./ContactMe.css"
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import emailjs from '@emailjs/browser';


function ContactMe() {
    const [error, setError] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name:'',
        email: '',
        message: '',
    });

    const handleFormChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    const handleSend = (e) => {
        e.preventDefault();
        console.log("email data:", formData)
        emailjs.send('service_3w4ab6e', "template_c4zmswb", formData, {
            publicKey: 'Y5qkrGjlr7gWwSElx'
        }).then(
            (response) => {
                console.log("Email sent.", response.status, response.text)
                setSentSuccess(true)
                setFormData({
                    name:'',
                    email: '',
                    message: '',
                })
            },
            (error) => {
                console.log("Email failed to send.", error)
                setError(false)
            }
        )
    };

    return (
        <div id="contact">
            <motion.div
                className="left-container"
                initial={{
                    opacity: 1,
                    y: 30
                }}
                whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: .5 // Animation duration
                    }
                }}>
                <div className="headings">
                    <p className="display">CONTACT ME</p>
                    <p className="header1">Email & Phone</p>
                </div>
                <div className="links">
                    <Link to="https://www.linkedin.com/in/g-clements/">
                    <BsLinkedin
                        className="linkedin"
                        />
                    </Link>
                    <Link to="https://www.instagram.com/gabrieelclem/">
                        <BsInstagram
                            className="instagram"/>
                    </Link>
                </div>
            </motion.div>
            <motion.div
                className="right-container"
                initial={{
                    opacity: 1,
                    y: 30
                }}
                whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: .5 // Animation duration
                    }
                }}
                >
                <form on onSubmit={handleSend}>
                    <input
                        required
                        type="text"
                        label="name"
                        name="name"
                        value={formData.name}
                        onChange={handleFormChange}
                        placeholder="Name"></input>
                    <input
                        required
                        type="email"
                        label="email"
                        name="email"
                        value={formData.email}
                        onChange={handleFormChange}
                        placeholder="Email"></input>
                    <textarea
                        name="message"
                        label="message"
                        value={formData.message}
                        onChange={handleFormChange}
                        placeholder="Message"></textarea>
                    <button type="submit">
                            SEND
                    </button>
                </form>
                {sentSuccess && (<p>Message Sent!</p>)}
                {error && (<p>Message failed to send.</p>)}
                </motion.div>
        </div>

    )
}

export default ContactMe;
