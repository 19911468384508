import "./AboutMe.css"
import gabriel from "./images/About Me Photo.png";
import { motion } from "framer-motion";


function AboutMe() {
    return(
        <div id="about-me">
            <div className="description">
                <motion.p
                    className="display"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: .5 // Animation duration
                        }
                    }}
                >
                        ABOUT ME
                </motion.p>
                <motion.p
                    className="header1"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: .5 // Animation duration
                        }
                    }}
                    >
                        Who is Gabriel Clements?
                </motion.p>
                <motion.div
                    className="paragraph2"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: .5 // Animation duration
                        }
                    }}>
                    &emsp;I am a passionate UX designer and researcher with a deep interest in design. With experience in leading design projects, my goal as a designer is to create user-centric solutions that enhance engagement and satisfaction.
                </motion.div>
                <br></br>
                <motion.div
                    className="paragraph2"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: .5 // Animation duration
                        }
                    }}
                    >
                    &emsp;My hobbies include photography, drawing, hiking, and playing the string bass, all of which help keep my creativity alive. I am dedicated to making a meaningful impact through thoughtful and innovative design, with a desire to connect people through my work.
                </motion.div>
            </div>
            <motion.img
                src={gabriel}
                alt="Gabriel Clements"
                className="placeholder"
                initial={{
                    opacity: 1,
                    y: 30
                }}
                whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: .5 // Animation duration
                    }
                }}
                 />
        </div>
    )
}

export default AboutMe;
