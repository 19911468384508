import "./Sketchbook.css"
import Popup from "./Popup";
import { useState, useEffect } from "react";
import Drawings from "./Drawings";



function Sketchbook() {
    const [popup, setPopup] = useState(false);
    const [image, setImage] = useState();
    const [drawingsData, setDrawingsData] = useState([])
    const [noStrapi, setNoStrapi] = useState(false)

    useEffect(() => {
        const getDrawings = async() => {
            try {
                const response = await fetch (`https://admin.gabrielclements.com/api/sketches?populate=*`)

                if (!response.ok) {
                    throw new Error('Failed to render drawings.')
                }
                const data = await response.json();
                setDrawingsData(data.data)
            } catch(error) {
                console.error("Error fetching drawings from strapi: ", error.message)
                setDrawingsData(Drawings)
                setNoStrapi(true);
            }
        };

        getDrawings();
    }, [])

    // function getDrawings() {
    //     fetch(`https://admin.gabrielclements.com/api/sketches?populate=*`)
    //         .then(res => res.json())
    //         .then(sketches => {
    //         setDrawingsData(sketches.data);
    //     })
    // }
    // console.log("drawings data:", drawingsData)

    // useEffect(() => {
    //     getDrawings();
    // }, []);

    // created function so we don't have to type out long name to find url
    function getURL(drawing) {
        return "https://admin.gabrielclements.com" + drawing.attributes.sketch.data.attributes.url;
    }

    function getAltText(drawing) {
        return drawing.attributes.alt_text;
    }

    const handlePopup = (image) => {
        setPopup(true);
        setImage(image)
    }

    return(
        <>
            <div id="sketchbook-all">
                <p className="display">
                    MY SKETCHBOOK
                </p>
                <p className="header1">Photography & Artwork </p>
                <div className="grid-container">
                    {!noStrapi && drawingsData.map(drawing => {
                        return (
                            <div className="grid-item"
                                onClick={() => handlePopup(getURL(drawing))}>
                                <img src={getURL(drawing)} alt={getAltText(drawing)} key={drawingsData.indexOf(drawing)}/>
                            </div>
                        )
                    })}
                    {noStrapi &&
                        drawingsData.slice(0,10).map(drawing => {
                            console.log("drawing: ", drawing)
                            return (
                                <div className="grid-item"
                                    onClick={() => handlePopup(drawing.drawing)}>
                                    <img src={drawing.drawing} alt={drawing.alt}key={drawingsData.indexOf(drawing)}/>
                                </div>
                            )
                    })}
                </div>
                <Popup trigger={popup} setTrigger={setPopup} image={image}/>
            </div>
        </>
    )
}

export default Sketchbook;
