import "./MyWork1.css";
import React from "react";
import Popup from "./Popup";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { format } from 'date-fns';
import Projects from "./Projects";
import { Link } from "react-router-dom";


function ProjectBlockLeft(props) {
    return (
        <>
            <motion.div
                className="project-block-left"
                initial={{
                    opacity: 1,
                    // if odd index card,slide from right instead of left
                    x: 30
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0, // Slide in to its original position
                    transition: {
                      duration: 1 // Animation duration
                    }
                  }}
                  viewport={{ once: false }}
                >
                {props.pdf ?
                    <img
                        className="cover-image"
                        src={props.cover}
                        alt={props.title}
                        onClick={() => props.handlePopup(props.pdf)}
                    />
                    :
                    <img
                        className="cover-image"
                        src={props.cover}
                        alt={props.title}
                    />
                }
                <div className="project-info">
                    <div className="date header2">
                        {props.date.toUpperCase()}
                    </div>
                    <div className="description">
                        <div className="paragraph1">
                            {props.description}
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
}

function ProjectBlockRight(props) {
    return (
        <>
            <motion.div
                className="project-block-right"
                initial={{
                    opacity: 1,
                    // if odd index card,slide from right instead of left
                    x: -50
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0, // Slide in to its original position
                    transition: {
                      duration: 1 // Animation duration
                    }
                  }}
                  viewport={{ once: true }}
                >
                <div className="project-info">
                    <div className="date-container">
                        <div className="date header2">
                            {props.date.toUpperCase()}
                        </div>
                    </div>
                    <div className="description">
                        <div className="paragraph1">
                            {props.description}
                        </div>
                    </div>
                </div>
                {props.pdf ?
                    <img
                        className="cover-image"
                        src={props.cover}
                        alt={props.title}
                        onClick={() => props.handlePopup(props.pdf)}
                    />
                    :
                    <img
                        className="cover-image"
                        src={props.cover}
                        alt={props.title}
                    />
                }
            </motion.div>
        </>
    )
}


function MyWork() {
    const [popup, setPopup] = useState(false);
    const [pdf, setPdf] = useState();
    const [projects, setProjects] = useState([]);
    const [noStrapi, setNoStrapi] = useState(false);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await fetch(`https://admin.gabrielclements.com/api/projects?populate=*`)

                if (!response.ok) {
                    throw new Error('Failed to fetch projects from strapi')
                }

                const data = await response.json();
                setProjects(data.data)
            } catch (error) {
                console.error('Error fetching projects from Strapi: ', error.message);
                setNoStrapi(true);
            }
        };
        getProjects();
    }, [])


    // gets the url for the pdf
    function getURL(project) {
        return "https://admin.gabrielclements.com"+project.attributes.pdf.data[0].attributes.url
    }

    // get the cover
    function getCover(project) {
        return "https://admin.gabrielclements.com/"+ project.attributes.cover.data.attributes.url
    }

    //get title for alternative text for cover
    function getTitle(project) {
        return project.attributes.title
    }

    // get project date
    function getDate(project) {
        let date = project.attributes.date
        let formattedDate = format(date, 'MMMM yyyy')
        return formattedDate
    }

    // get project description
    function getDescription(project) {
        return project.attributes.description
    }


    const handlePopup = (pdf) => {
        setPopup(true);
        setPdf(pdf);
    }

    return(
    <>
        <div id="my-work">
            <motion.p
                className="display"
                initial={{
                    opacity: 1,
                    y: 30
                }}
                whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: .5 // Animation duration
                    }
                }}
            >
                    MY WORK
            </motion.p>
            <motion.p
                className="header1"
                initial={{
                    opacity: 1,
                    y: 30
                }}
                whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: .5 // Animation duration
                    }
                }}>
                UI/UX Case Studies
            </motion.p>
            { projects &&
                <div className="container">
                    {projects.map(project => {
                        if (projects.indexOf(project) % 2 === 0) {
                            return (
                                <ProjectBlockLeft
                                    date={getDate(project)}
                                    title={getTitle(project)}
                                    pdf= {getURL(project)}
                                    cover= {getCover(project)}
                                    project={project}
                                    handlePopup={handlePopup}
                                    description={getDescription(project)}
                                    key={projects.indexOf(project)}
                                />
                            )
                        } else {
                            return (
                                <ProjectBlockRight
                                    date={getDate(project)}
                                    title={getTitle(project)}
                                    pdf= {getURL(project)}
                                    cover= {getCover(project)}
                                    project={project}
                                    handlePopup={handlePopup}
                                    description={getDescription(project)}
                                    key={projects.indexOf(project)}
                                />
                            )
                        }
                    })}
                </div>
            }
            { noStrapi &&
                <div className="container">
                    {Projects.map(project => {
                        if (Projects.indexOf(project) % 2 === 0) {
                            return (
                                <Link to={project.pdf} target="_blank">
                                    <ProjectBlockLeft
                                        date={project.date}
                                        title={project.title}
                                        cover= {project.cover}
                                        description={project.description}
                                        key={Projects.indexOf(project)}
                                    />
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={project.pdf} target="_blank">
                                    <ProjectBlockRight
                                        date={project.date}
                                        title={project.title}
                                        cover= {project.cover}
                                        description={project.description}
                                        key={Projects.indexOf(project)}
                                    />
                                </Link>
                            )
                        }
                    })}
                </div>

            }
            <Popup trigger={popup} setTrigger={setPopup} pdf={pdf} />
        </div>
    </>
    )
  }

export default MyWork;
