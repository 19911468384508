import "./Popup.css";

function Popup(props) {
    // onClick for each image this function will render
    const image = props.image;
    const pdf = props.pdf;

    return (props.trigger) ? (
        <div id="focus-bg">
            <div
                className="btn-close"
                onClick={() => props.setTrigger(false)}
            >
                <button>x</button>
            </div>
            {image &&
                <img src={image} className="placeholder" alt="drawing popup" />
            }
            {pdf &&
                <iframe
                    src={pdf}
                    title={props.title}
                    className="placeholder"
                    aria-label={props.title}
                    width="90%" />
            }
        </div>
    ) : ""
}

export default Popup;
