import empower from "./images/Empower.webp";
import nomie from "./images/nomie.webp";
import studio_premium from "./images/Studio Premium.png";
import google_ventures from "./images/google-ventures.webp";

const Projects = [
    {
        date: 'February 2024',
        title: 'Nomie - Your Classroom Facilitator',
        pdf: 'https://drive.google.com/file/d/1oZnXNchbTwfWNxxt38B99RPQvmbYKHWl/view?usp=sharing',
        cover: nomie,
        description: 'Nomie is a web tool that simplifies creating new connections and improving the joy of collaboration, designing activities and exercises for CÉGEP students that encourage inclusivity, confidence, trust, and collaboration between students forming new connections.'
    },
    {
        date: 'December 2023',
        title: 'Studio - What Makes an Irresistible Offer?',
        pdf: 'https://drive.google.com/file/d/1f8oHyOyiELit9h-IY0X7JYOu1pslC5yI/view?usp=sharing',
        cover: studio_premium,
        description: 'The goal of this project is to develop a premium version of a successful music media app to enhance user experience and business revenue. Studio is a free version of a music media app that has been very successful and is investing in developing a premium version of their app for users to subscribe to. In short, this project aims to elevate user satisfaction, and drive revenue growth.'
    },
    {
        date: 'November 2023',
        title: 'Google Ventures Design Sprint',
        pdf: 'https://drive.google.com/file/d/1xLU41DkfbFE6syyVYj5gFREDuE7VUBne/view?usp=sharing',
        cover: google_ventures,
        description: 'This design sprint aims to increase customer satisfaction and design a way to improve the experience of viewing art in a museum or gallery. This includes paintings, sculptures, installations, and so on.'
    },
    {
        date: 'July 2023',
        title: 'Empower - Intimidating to Inspiring',
        pdf: 'https://drive.google.com/file/d/1_bJp08WNpkz3bnDtPJA07bGGLVCU1Hcv/view?usp=sharing',
        cover: empower,
        description: 'As someone who has been working out for over two years, I’ve noticed that a lot of people have become interested in fitness like it’s become a trend. However, I fail to see many staying consistent or reaching their goals in the gym. This made me wonder--  What exactly is causing this fallout in gym members?'
    }

]

export default Projects;
