import MyWork from './MyWork';
import MySketchbook from './MySketchbook';
import Landing from './Landing';
import AboutMe from './AboutMe';
import ContactMe from './ContactMe';

function SinglePage() {
    return(
        <>
                <Landing />
            <div id="spa">
                <AboutMe />
                <MyWork />
                <MySketchbook />
                <ContactMe />
            </div>
        </>
    )
}

export default SinglePage;
