import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav from './Nav';
import SinglePage from './spa';
import Sketchbook from './Sketchbook';

function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '');
  return (
    <>
    <BrowserRouter basename = {basename}>
      <Nav />
       <div className="router-container">
          <Routes>
            <Route path="/" element={<SinglePage />} />
            <Route path="/sketchbook" element={<Sketchbook />} />
          </Routes>
        </div>
    </BrowserRouter>
    </>
  );
}

export default App;
