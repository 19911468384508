import "./Landing.css"
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import default_banner from "./images/banner.jpg"


function Landing() {
    // const [banner, setBanner] = useState()
    const [bannerURL, setBannerURL] = useState()
    const [altText, setAltText] = useState()
    // function getBanner() {
    //     fetch(`https://admin.gabrielclements.com/api/banners?populate=*`)
    //         .then(res => res.json())
    //         .then(banner => {
    //         setBanner(banner.data);
    //     })
    // }

    // useEffect(() => {
    //     getBanner();
    // }, []);

    useEffect(() => {
        const getBanner = async () => {
            try {
                const response = await fetch(`https://admin.gabrielclements.com/api/banners?populate=*`);

                if (!response.ok) {
                    throw new Error('Failed to fetch banner');
                }

                const data = await response.json();
                // when server is back up double check to see what the data looks lke
                // corresponding with getURL()
                let url ="https://admin.gabrielclements.com" + data.data[0].attributes.banner.data.attributes.url;

                // corresponding with getAltText()
                let alt_text = "https://admin.gabrielclements.com" + data.data[0].attributes.alt_text;
                setBannerURL(url);
                setAltText(alt_text)
            } catch (error) {
                console.error('Error fetching banner:', error.message);
                setBannerURL(default_banner)
                setAltText("blue gradient banner")
            }
        };

        getBanner();
    }, []);

    // function getURL() {
    //     return "https://admin.gabrielclements.com" + banner[0].attributes.banner.data.attributes.url
    // }

    // function getAltText() {
    //     return "https://admin.gabrielclements.com" + banner[0].attributes.alt_text
    // }


    return (
        <>
            <div id="landing">
                { bannerURL && (
                <img
                    className="placeholder"
                    src={bannerURL}
                    alt={altText}
                />
                )}
                <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                    duration: 1,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    <p className="display">GABRIEL</p>
                </motion.div>
                <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                    duration: 1,
                    delay: 1.0,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                    <p className="display">CLEMENTS</p>
                </motion.div>
                <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                    duration: 1,
                    delay: 1.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                    <p className="header1">Designer & Creative </p>
                </motion.div>


            </div>
        </>
    )
}

export default Landing;
