import dragons from './images/dragons 2.jpeg'
import drawings1 from "./images/drawings1.jpg";
import drawings2 from "./images/drawings2.jpg";
import drawings3 from "./images/drawings3.jpg";
import drawigs4 from "./images/drawigs4.jpg";
import photo1 from "./images/ClementsG_FN02.jpg";
import photo2 from "./images/ClementsG_FN05.jpg";
import photo3 from "./images/ClementsG_FN11.jpg";
import photo4 from "./images/Favorite #3.jpg";



const Drawings = [
    {drawing: dragons, alt: "dragons"},
    {drawing: drawings1, alt: "green goku"},
    {drawing: drawings2, alt:"batman"},
    {drawing: drawings3, alt: "Group photo"},
    {drawing: drawigs4, alt: "little girl and teddy bear"},
    {drawing: photo1, alt: "basketball hoop"},
    {drawing: photo2, alt: "black and white park bench"},
    {drawing: photo3, alt: "chandelier up close"},
    {drawing: photo4, alt: "up close shot"}
]

export default Drawings;
