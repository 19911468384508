import "./MySketchbook.css";
import Popup from "./Popup";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Drawings from "./Drawings";

function MySketchbook() {
    const [popup, setPopup] = useState(false);
    const [image, setImage] = useState();
    const [drawingsData, setDrawingsData] = useState([])
    const [noStrapi, setNoStrapi] = useState(false)

    useEffect(() => {
        const getDrawings = async() => {
            try {
                const response = await fetch (`https://admin.gabrielclements.com/api/sketches?populate=*`)

                if (!response.ok) {
                    throw new Error('Failed to render drawings.')
                }
                const data = await response.json();
                setDrawingsData(data.data)
            } catch(error) {
                console.error("Error fetching drawings from strapi: ", error.message)
                setDrawingsData(Drawings)
                setNoStrapi(true);
            }
        };

        getDrawings();
    }, []);

    const handlePopup = (image) => {
        setPopup(true);
        setImage(image);
    }

    function getURL(drawing) {
        return "https://admin.gabrielclements.com" + drawing.attributes.sketch.data.attributes.url;
    };

    function getAltText(drawing) {
        return drawing.attributes.alt_text;
    };

    return(
        <>
            <div id="sketchbook">
                <motion.p
                    className="display"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: .5 // Animation duration
                        }
                    }}
                >
                    MY SKETCHBOOK
                </motion.p>
                <motion.p
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: .5
                        }
                    }}
                    className="header1">
                    Photography & Artwork
                </motion.p>
                <motion.div
                    className="grid-container"
                    initial={{
                        opacity: 1,
                        y: 30
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: .5
                        }
                    }}>
                    {!noStrapi &&
                        drawingsData.slice(0,10).map(drawing => {
                            return (
                                <div
                                    key={drawingsData.indexOf(drawing)}
                                    className="grid-item"
                                    onClick={() => handlePopup(getURL(drawing))}>
                                    <img src={getURL(drawing)} alt={getAltText(drawing)} key={drawingsData.indexOf(drawing)}/>
                                </div>
                            )
                    })
                    }
                    {noStrapi &&
                        drawingsData.slice(0,10).map(drawing => {
                            return (
                                <div className="grid-item"
                                    key={drawingsData.indexOf(drawing)}
                                    onClick={() => handlePopup(drawing.drawing)}>
                                    <img src={drawing.drawing} alt={drawing.alt} key={drawingsData.indexOf(drawing)}/>
                                </div>
                            )
                    })}
                </motion.div>
                <div className="view-all">
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.8 }}
                    >
                        <NavLink to="/sketchbook">VIEW ALL &#160; &#40;{drawingsData.length}&#41;</NavLink>
                    </motion.button>
                </div>
                <Popup trigger={popup} setTrigger={setPopup} image={image}/>
            </div>
        </>
    )
}

export default MySketchbook;
