import './Nav.css'
import { HashLink } from 'react-router-hash-link';
import { NavLink, useLocation } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";
import { useState, useEffect } from 'react';
import { IoIosClose } from "react-icons/io";



function Nav() {
    const [nav, setNav] = useState(false)
    const [noNav, setNoNav] = useState(false)
    const location = useLocation();

    function getLocation() {
        if (location.pathname === "/sketchbook") {
            setNoNav(true)
        } else {
            setNoNav(false)
        }
    }

    useEffect(() => {
        getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])


    return (
        <>
            <nav className="nav-bar">
                <div className='logo-and-toggle'>
                    <div className="logo">
                        <NavLink to="/">
                            GC
                        </NavLink>
                    </div>
                    <div
                        onClick= {()=> setNav(!nav)}
                        className='navbar-toggle'>
                    {nav ?  <IoIosClose /> : <CiMenuBurger/>}
                    </div>
                </div>
                { !noNav && (
                    <ul
                    className={nav? "active" : "nav-buttons"}>
                    <li className="about-me">
                        <HashLink to="#about-me">
                            About me
                        </HashLink>
                    </li>
                    <li className="my-work">
                        <HashLink to="#my-work">
                            My work
                        </HashLink>
                    </li>
                    <li className="my-sketchbook">
                        <HashLink to="#sketchbook">
                            My sketchbook
                        </HashLink>
                    </li>
                    <li className="contact">
                        <a href="#contact">
                            Contact me
                        </a>
                    </li>
                </ul>
                )}
            </nav>
        </>
    )
}


export default Nav;
